unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls,
  Vcl.Imaging.jpeg, Vcl.Imaging.pngimage, WEBLib.ExtCtrls;

type
  TForm1 = class(TForm)
    WebImageControl1: TImageControl;
    WebLinkLabel1: TLinkLabel;
    WebImageControl2: TImageControl;
    WebLinkLabel2: TLinkLabel;
    WebResponsiveGridPanel1: TResponsiveGridPanel;
    WebPanel1: TPanel;
    WebPanel2: TPanel;
    WebPanel5: TPanel;
    WebImageControl4: TImageControl;
    procedure WebFormCreate(Sender: TObject);


  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;
  Link: STRING;

implementation

{$R *.dfm}

procedure TForm1.WebFormCreate(Sender: TObject);
begin

     WebLinkLabel1.Caption:='<a href="/sinneplus/sinneplus.html">SinnePlusGdB 3.5</a>';
     WebLinkLabel2.Caption:='<a href="/tumor/tumorgdb.html">TumorGdB 1.0</a>';

end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebResponsiveGridPanel1 := TResponsiveGridPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  WebImageControl1 := TImageControl.Create(Self);
  WebLinkLabel1 := TLinkLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebImageControl2 := TImageControl.Create(Self);
  WebLinkLabel2 := TLinkLabel.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebImageControl4 := TImageControl.Create(Self);

  WebResponsiveGridPanel1.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  WebImageControl1.BeforeLoadDFMValues;
  WebLinkLabel1.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebImageControl2.BeforeLoadDFMValues;
  WebLinkLabel2.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebImageControl4.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 1163;
    Height := 493;
    Caption := 'Starter 2.2';
    ElementPosition := epRelative;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebResponsiveGridPanel1.SetParentComponent(Self);
    WebResponsiveGridPanel1.Name := 'WebResponsiveGridPanel1';
    WebResponsiveGridPanel1.Left := 0;
    WebResponsiveGridPanel1.Top := 100;
    WebResponsiveGridPanel1.Width := 1163;
    WebResponsiveGridPanel1.Height := 393;
    WebResponsiveGridPanel1.Align := alClient;
    WebResponsiveGridPanel1.ChildOrder := 8;
    WebResponsiveGridPanel1.ControlCollection.Clear;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel1;
    end;
    with WebResponsiveGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebPanel2;
    end;
    WebResponsiveGridPanel1.Color := clWhite;
    WebResponsiveGridPanel1.Layout.Clear;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Smartphone';
      Style := '1fr';
      Width := 575;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Tablet';
      Style := '1fr 1fr';
      Width := 768;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Desktop';
      Style := '1fr 1fr 1fr';
      Width := 991;
    end;
    with WebResponsiveGridPanel1.Layout.Add do
    begin
      Description := 'Large Desktop';
      Style := '1fr 1fr 1fr 1fr';
      Width := 1199;
    end;
    WebPanel1.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 282;
    WebPanel1.Height := 258;
    WebPanel1.ChildOrder := 1;
    WebImageControl1.SetParentComponent(WebPanel1);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 3;
    WebImageControl1.Top := 3;
    WebImageControl1.Width := 275;
    WebImageControl1.Height := 174;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 1;
    WebImageControl1.ElementPosition := epRelative;
    WebImageControl1.Picture.LoadFromFile('Unit1.WebPanel1.WebImageControl1.Picture.png');
    WebLinkLabel1.SetParentComponent(WebPanel1);
    WebLinkLabel1.Name := 'WebLinkLabel1';
    WebLinkLabel1.Left := 52;
    WebLinkLabel1.Top := 211;
    WebLinkLabel1.Width := 168;
    WebLinkLabel1.Height := 23;
    WebLinkLabel1.Alignment := taCenter;
    WebLinkLabel1.Anchors := [];
    WebLinkLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLinkLabel1.Font.Color := clWindowText;
    WebLinkLabel1.Font.Height := -19;
    WebLinkLabel1.Font.Name := 'Tahoma';
    WebLinkLabel1.Font.Style := [fsBold];
    WebLinkLabel1.ParentFont := False;
    WebLinkLabel1.HeightPercent := 100.000000000000000000;
    WebLinkLabel1.WidthPercent := 100.000000000000000000;
    WebLinkLabel1.Caption := 'SinnePlusGdB 3.5';
    WebPanel2.SetParentComponent(WebResponsiveGridPanel1);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 291;
    WebPanel2.Top := 0;
    WebPanel2.Width := 282;
    WebPanel2.Height := 258;
    WebPanel2.ChildOrder := 1;
    WebImageControl2.SetParentComponent(WebPanel2);
    WebImageControl2.Name := 'WebImageControl2';
    WebImageControl2.Left := 4;
    WebImageControl2.Top := 3;
    WebImageControl2.Width := 274;
    WebImageControl2.Height := 174;
    WebImageControl2.HeightPercent := 100.000000000000000000;
    WebImageControl2.WidthPercent := 100.000000000000000000;
    WebImageControl2.Anchors := [];
    WebImageControl2.ChildOrder := 1;
    WebImageControl2.ElementPosition := epRelative;
    WebImageControl2.Picture.LoadFromFile('Unit1.WebPanel2.WebImageControl2.Picture.png');
    WebLinkLabel2.SetParentComponent(WebPanel2);
    WebLinkLabel2.Name := 'WebLinkLabel2';
    WebLinkLabel2.Left := 74;
    WebLinkLabel2.Top := 211;
    WebLinkLabel2.Width := 137;
    WebLinkLabel2.Height := 23;
    WebLinkLabel2.Alignment := taCenter;
    WebLinkLabel2.Anchors := [];
    WebLinkLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLinkLabel2.Font.Color := clWindowText;
    WebLinkLabel2.Font.Height := -19;
    WebLinkLabel2.Font.Name := 'Tahoma';
    WebLinkLabel2.Font.Style := [fsBold];
    WebLinkLabel2.ParentFont := False;
    WebLinkLabel2.HeightPercent := 100.000000000000000000;
    WebLinkLabel2.WidthPercent := 100.000000000000000000;
    WebLinkLabel2.Caption := 'TumorGdB 1.0';
    WebPanel5.SetParentComponent(Self);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 1163;
    WebPanel5.Height := 100;
    WebPanel5.WidthStyle := ssAuto;
    WebPanel5.Align := alTop;
    WebPanel5.BorderColor := clWhite;
    WebPanel5.ChildOrder := 2;
    WebPanel5.Color := clWhite;
    WebPanel5.ElementFont := efCSS;
    WebPanel5.ElementPosition := epIgnore;
    WebImageControl4.SetParentComponent(WebPanel5);
    WebImageControl4.Name := 'WebImageControl4';
    WebImageControl4.Left := 11;
    WebImageControl4.Top := 16;
    WebImageControl4.Width := 271;
    WebImageControl4.Height := 54;
    WebImageControl4.HeightStyle := ssAuto;
    WebImageControl4.HeightPercent := 100.000000000000000000;
    WebImageControl4.WidthPercent := 100.000000000000000000;
    WebImageControl4.Anchors := [akLeft];
    WebImageControl4.ElementFont := efCSS;
    WebImageControl4.ElementPosition := epIgnore;
    WebImageControl4.Picture.LoadFromFile('Unit1.WebPanel5.WebImageControl4.Picture.png');
  finally
    WebResponsiveGridPanel1.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    WebImageControl1.AfterLoadDFMValues;
    WebLinkLabel1.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebImageControl2.AfterLoadDFMValues;
    WebLinkLabel2.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebImageControl4.AfterLoadDFMValues;
  end;
end;

end.
